import BankIDPage from "components/bankid";
import { withIronSessionSsr } from "iron-session/next";
import { getSessionOptions } from "lib/session";
import { GetServerSidePropsContext } from "next";
import { getUserAgent } from "utils/getUserAgent";
export interface LoginProps {
  signupEmail?: string;
  signupOrgId?: string;
  autoSignup?: boolean;
}
export default function LoginView(props: LoginProps) {
  const {
    signupOrgId,
    signupEmail,
    autoSignup
  } = props;
  return <BankIDPage flowType="login"
  // When flowType="login" and autoSignup="true" with necessary details, a user will be created if it's not already exist.
  autoSignup={autoSignup} signupOrgId={signupOrgId} signupEmail={signupEmail} />;
}
export const getServerSideProps = withIronSessionSsr(({
  req,
  query
}: GetServerSidePropsContext) => {
  const authenticatedPerson = req.session.user;
  if (authenticatedPerson) {
    return {
      redirect: {
        destination: "/",
        permanent: false
      }
    };
  }
  const autoSignup = query.autoSignup === "true";
  const signupOrgId = query.orgId || null;
  const signupEmail = query.email || null;
  return {
    props: {
      ...getUserAgent({
        req
      }).props,
      autoSignup,
      signupOrgId,
      signupEmail
    }
  };
}, getSessionOptions());